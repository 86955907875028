import { UpdateWizardReportRequest } from '@wix/ambassador-accessibility-v1-wizard-report/build/cjs/types.impl';
import { updateWizardReport } from '@wix/ambassador-accessibility-v1-wizard-report/http';
import { createHeaders } from '@wix/headers';
import { createHttpClient } from '@wix/http-client';

export const updateWizardMonitoringReport = async (
  appInstance: string,
  payload: UpdateWizardReportRequest,
) => {
  const headers = createHeaders({
    signedInstance: appInstance,
  });
  const httpClient = createHttpClient({ headers });

  const { data } = await httpClient.request(updateWizardReport(payload));

  return data;
};
